<!--
 * @Author: Libra
 * @Date: 2022-08-10 12:59:51
 * @LastEditTime: 2022-08-10 16:14:44
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/components/deviceDetect/components/audioBar.vue
-->
<template>
  <div class="bar-container"></div>
</template>

<script>
export default {
  name: 'AudioBar',
  props: {
    volume: {
      type: Number,
      default: 0
    }
  },
  watch: {
    volume() {
      this.createVolumeBar()
    }
  },
  mounted() {
    this.createVolumeBar()
  },
  methods: {
    // 生成音量条
    createVolumeBar() {
      const barContainer = document.querySelector('.bar-container')
      const oldbars = document.querySelectorAll('.bar')
      oldbars.forEach((bar) => {
        bar.remove()
      })
      const bars = new Array(40)
        .fill('')
        .map(
          (item, index) =>
            `<div class="${
              this.volume * 40 > index ? 'bar active' : 'bar unactive'
            }"></div>`
        )
      for (const item of bars) {
        barContainer.innerHTML += item
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bar-container {
  display: flex;
  align-items: center;
  align-self: flex-start;
  ::v-deep .bar {
    width: 6px;
    height: 40px;
    margin-right: 2px;
    border-radius: 2px;
    box-sizing: border-box;
  }
  ::v-deep .active {
    background-color: #f35a5a;
  }
  ::v-deep .unactive {
    border: 1px solid #666;
  }
}
</style>
