var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        height: "450px",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
      },
    },
    [
      _c(
        "GlobalDialog",
        {
          attrs: { dialogVisible: _vm.allowCameraVisible, title: "提示" },
          on: {
            "dialog-cancel": function ($event) {
              _vm.allowCameraVisible = false
            },
          },
        },
        [
          _c("div", { staticClass: "detect-result" }, [
            _c("i", { staticClass: "iconfont iconshexiangtou2" }),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("请允许使用摄像头"),
              ]),
              _c("span", { staticClass: "content" }, [
                _vm._v("点击浏览器右上角，开启摄像头"),
              ]),
              _c("span", { staticClass: "content2" }, [
                _vm._v("设备开启后，刷新页面方可生效"),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 1,
              expression: "step === 1",
            },
          ],
          staticClass: "step",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("设备连接")]),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v("设备检测前请确认设备连接了摄像头、麦克风、扬声器和网络"),
          ]),
          _c("div", { staticClass: "devices" }, [
            _c("div", { staticClass: "items" }, [
              _vm.hasCameraDevice
                ? _c(
                    "i",
                    { staticClass: "item pass iconfont iconshexiangtou2" },
                    [
                      _c("i", {
                        staticClass: "pos iconfont iconduigoutianchong-01",
                      }),
                    ]
                  )
                : _c(
                    "i",
                    { staticClass: "item no-pass iconfont iconshexiangtou2" },
                    [
                      _c("i", {
                        staticClass: "pos iconfont iconicon_tips_wrong",
                      }),
                    ]
                  ),
            ]),
            _c("div", { staticClass: "items" }, [
              _vm.hasMicrophoneDevice
                ? _c("i", { staticClass: "item pass iconfont iconluyin" }, [
                    _c("i", {
                      staticClass: "pos iconfont iconduigoutianchong-01",
                    }),
                  ])
                : _c(
                    "i",
                    {
                      staticClass: "item no-pass iconfont iconluyin",
                      attrs: { "no-pass": "" },
                    },
                    [
                      _c("i", {
                        staticClass: "pos iconfont iconicon_tips_wrong",
                      }),
                    ]
                  ),
            ]),
            _c("div", { staticClass: "items" }, [
              _vm.hasSpeakerDevice
                ? _c("i", { staticClass: "item pass iconfont iconshengyin" }, [
                    _c("i", {
                      staticClass: "pos iconfont iconduigoutianchong-01",
                    }),
                  ])
                : _c(
                    "i",
                    { staticClass: "item no-pass iconfont iconshengyin" },
                    [
                      _c("i", {
                        staticClass: "pos iconfont iconicon_tips_wrong",
                      }),
                    ]
                  ),
            ]),
            _c("div", { staticClass: "items" }, [
              _vm.hasNetworkConnect
                ? _c("i", { staticClass: "item pass iconfont iconyuyan" }, [
                    _c("i", {
                      staticClass: "pos iconfont iconduigoutianchong-01",
                    }),
                  ])
                : _c("i", { staticClass: "item no-pass iconfont iconyuyan" }, [
                    _c("i", {
                      staticClass: "pos iconfont iconicon_tips_wrong",
                    }),
                  ]),
            ]),
          ]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isAllPass,
                  expression: "!isAllPass",
                },
              ],
              staticClass: "tips",
            },
            [_vm._v("请允许浏览器及网页访问以上设备")]
          ),
          !_vm.isAllPass
            ? _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.getDevices },
                },
                [_vm._v("重新连接")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(true)
                    },
                  },
                },
                [_vm._v("开始检测")]
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 2,
              expression: "step === 2",
            },
          ],
          staticClass: "step",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("摄像头检测")]),
          _c(
            "div",
            { staticClass: "select-container" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("摄像头选择")]),
              _c(
                "el-select",
                {
                  on: { change: _vm.handleCameraChange },
                  model: {
                    value: _vm.camera,
                    callback: function ($$v) {
                      _vm.camera = $$v
                    },
                    expression: "camera",
                  },
                },
                _vm._l(_vm.cameraOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cameraHasInit,
                  expression: "cameraHasInit",
                },
              ],
            },
            [_c("div", { attrs: { id: "camera-video" } })]
          ),
          _c("span", { staticClass: "hint" }, [
            _vm._v("是否可以清楚的看到自己?"),
          ]),
          _c(
            "div",
            { staticClass: "btel-container" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(false)
                    },
                  },
                },
                [_vm._v("看不见")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(true)
                    },
                  },
                },
                [_vm._v("看得见")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 3,
              expression: "step === 3",
            },
          ],
          staticClass: "step",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("麦克风检测")]),
          _c(
            "div",
            { staticClass: "select-container" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("麦克风选择")]),
              _c(
                "el-select",
                {
                  on: { change: _vm.handleMicroChange },
                  model: {
                    value: _vm.micro,
                    callback: function ($$v) {
                      _vm.micro = $$v
                    },
                    expression: "micro",
                  },
                },
                _vm._l(_vm.microOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "say-hello" }, [
            _vm._v("对着麦克风说 哈喽 试试~"),
          ]),
          _c("audio-bar", { attrs: { volume: Number(_vm.volume) } }),
          _c("div", { attrs: { id: "audio-container" } }),
          _c("span", { staticClass: "hint" }, [
            _vm._v("是否可以看到音量图标跳动？"),
          ]),
          _c(
            "div",
            { staticClass: "btel-container" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(false)
                    },
                  },
                },
                [_vm._v("看不见")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(true)
                    },
                  },
                },
                [_vm._v("看得见")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 4,
              expression: "step === 4",
            },
          ],
          staticClass: "step",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("扬声器检测")]),
          _c(
            "div",
            { staticClass: "select-container" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("扬声器选择")]),
              _c(
                "el-select",
                {
                  on: { change: _vm.handleSpeakerChange },
                  model: {
                    value: _vm.speaker,
                    callback: function ($$v) {
                      _vm.speaker = $$v
                    },
                    expression: "speaker",
                  },
                },
                _vm._l(_vm.speakerOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("audio", {
            attrs: { id: "audio-player", src: _vm.url, controls: "" },
          }),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v("请点击上方播放按钮，打开电脑声音"),
          ]),
          _c(
            "div",
            { staticClass: "btel-container" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(false)
                    },
                  },
                },
                [_vm._v("听不见")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextStep(true)
                    },
                  },
                },
                [_vm._v("听得见")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 5,
              expression: "step === 5",
            },
          ],
          staticClass: "step",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("网络检测")]),
          _c("table", { staticClass: "net-item" }, [
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("系统")]),
              _c("td", { staticClass: "tdcontent" }, [
                _vm._v(_vm._s(_vm.system)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("浏览器")]),
              _c("td", { staticClass: "tdcontent" }, [
                _vm._v(_vm._s(_vm.browser)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("视频监控")]),
              _c("td", { staticClass: "tdcontent" }, [
                _vm.TRTCSupport
                  ? _c(
                      "span",
                      {
                        staticStyle: { color: "#5fa4f9", "font-size": "18px" },
                      },
                      [_vm._v("✓")]
                    )
                  : _c(
                      "span",
                      {
                        staticStyle: { color: "#f35a5a", "font-size": "18px" },
                      },
                      [_vm._v("✕")]
                    ),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("屏幕共享")]),
              _c("td", { staticClass: "tdcontent" }, [
                _vm.screenMediaSupport
                  ? _c(
                      "span",
                      {
                        staticStyle: { color: "#5fa4f9", "font-size": "18px" },
                      },
                      [_vm._v("✓")]
                    )
                  : _c(
                      "span",
                      {
                        staticStyle: { color: "#f35a5a", "font-size": "18px" },
                      },
                      [_vm._v("✕")]
                    ),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("网络延时：")]),
              _c("td", { staticClass: "tdcontent" }, [
                _vm._v(" " + _vm._s(_vm.rtt) + "ms "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("上行网络：")]),
              _c("td", { staticClass: "tdcontent" }, [
                _c("span", { class: _vm.getColorOfStr(_vm.uplinkQuality) }, [
                  _vm._v(
                    " " + _vm._s(_vm.getNetworkQuality(_vm.uplinkQuality)) + " "
                  ),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "tdtitle" }, [_vm._v("下行网络：")]),
              _c("td", { staticClass: "tdcontent" }, [
                _c("span", { class: _vm.getColorOfStr(_vm.downlinkQuality) }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getNetworkQuality(_vm.downlinkQuality)) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  on: {
                    click: () => {
                      _vm.clearStream()
                      _vm.step = 1
                      _vm.$emit("next")
                    },
                  },
                },
                [_vm._v("重新检测")]
              ),
              _vm.hasNext
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v(" 下一步 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }