<!--
 * @Author: Libra
 * @Date: 2022-08-10 12:09:28
 * @LastEditTime: 2024-05-22 13:59:09
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/components/deviceDetect/index.vue
-->
 <template>
  <div
style="height: 450px;
display: flex;
justify-content: center;
align-items: center;">
    <GlobalDialog
        :dialogVisible="allowCameraVisible"
        @dialog-cancel="allowCameraVisible = false"
        title="提示"
      >
        <div class="detect-result">
          <i class="iconfont iconshexiangtou2"></i>
          <div class="right">
            <span class="title">请允许使用摄像头</span>
            <span class="content">点击浏览器右上角，开启摄像头</span>
            <span class="content2">设备开启后，刷新页面方可生效</span>
          </div>
        </div>
    </GlobalDialog>
    <div class="step" v-show="step === 1">
    <div class="title">设备连接</div>
    <div class="subtitle">设备检测前请确认设备连接了摄像头、麦克风、扬声器和网络</div>
    <div class="devices">
      <div class="items">
        <i v-if="hasCameraDevice" class="item pass iconfont iconshexiangtou2">
          <i class="pos iconfont iconduigoutianchong-01"></i>
        </i>
        <i v-else class="item no-pass iconfont iconshexiangtou2">
          <i class="pos iconfont iconicon_tips_wrong"></i>
        </i>
      </div>
      <div class="items">
        <i
          v-if="hasMicrophoneDevice"
          class="item pass iconfont iconluyin"
        >
          <i class="pos iconfont iconduigoutianchong-01"></i>
        </i>
        <i
          no-pass
          v-else
          class="item no-pass iconfont iconluyin"
        >
          <i class="pos iconfont iconicon_tips_wrong"></i>
        </i>
      </div>
      <div class="items">
        <i v-if="hasSpeakerDevice" class="item pass iconfont iconshengyin">
          <i class="pos iconfont iconduigoutianchong-01"></i>
        </i>
        <i v-else class="item no-pass iconfont iconshengyin">
          <i class="pos iconfont iconicon_tips_wrong"></i>
        </i>
      </div>
      <div class="items">
        <i v-if="hasNetworkConnect" class="item pass iconfont iconyuyan">
          <i class="pos iconfont iconduigoutianchong-01"></i>
        </i>
        <i v-else class="item no-pass iconfont iconyuyan">
          <i class="pos iconfont iconicon_tips_wrong"></i>
        </i>
      </div>
    </div>
    <span class="tips" v-show="!isAllPass">请允许浏览器及网页访问以上设备</span>
    <el-button
      type="primary"
      class="btn"
      @click="getDevices"
      v-if="!isAllPass"
      >重新连接</el-button
    >
    <el-button type="primary" class="btn" @click="nextStep(true)" v-else>开始检测</el-button>
  </div>
  <div class="step" v-show="step === 2">
    <div class="title">摄像头检测</div>
    <div class="select-container">
      <span class="label">摄像头选择</span>
      <el-select
        @change="handleCameraChange"
        v-model="camera"
      >
        <el-option
          v-for="item in cameraOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div v-loading="cameraHasInit">
      <div id="camera-video"></div>
    </div>
    <span class="hint">是否可以清楚的看到自己?</span>
    <div class="btel-container">
      <el-button @click="nextStep(false)">看不见</el-button>
      <el-button type="primary" @click="nextStep(true)">看得见</el-button>
    </div>
  </div>
  <div class="step" v-show="step === 3">
    <div class="title">麦克风检测</div>
    <div class="select-container">
      <span class="label">麦克风选择</span>
      <el-select
        v-model="micro"
        @change="handleMicroChange"
      >
        <el-option
          v-for="item in microOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <span class="say-hello">对着麦克风说 哈喽 试试~</span>
    <audio-bar :volume="Number(volume)"></audio-bar>
    <div id="audio-container"></div>
    <span class="hint">是否可以看到音量图标跳动？</span>
    <div class="btel-container">
      <el-button @click="nextStep(false)">看不见</el-button>
      <el-button type="primary" @click="nextStep(true)">看得见</el-button>
    </div>
  </div>
  <div class="step" v-show="step === 4">
    <div class="title">扬声器检测</div>
    <div class="select-container">
      <span class="label">扬声器选择</span>
      <el-select
        v-model="speaker"
        @change="handleSpeakerChange"
      >
        <el-option
          v-for="item in speakerOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <audio id="audio-player" :src="url" controls></audio>
    <div style="margin-bottom: 10px;">请点击上方播放按钮，打开电脑声音</div>
    <div class="btel-container">
      <el-button @click="nextStep(false)">听不见</el-button>
      <el-button type="primary" @click="nextStep(true)">听得见</el-button>
    </div>
  </div>
  <div class="step" v-show="step === 5">
    <div class="title">网络检测</div>
    <table class="net-item">
      <tr>
        <td class="tdtitle">系统</td>
        <td class="tdcontent">{{ system }}</td>
      </tr>
      <tr>
        <td class="tdtitle">浏览器</td>
        <td class="tdcontent">{{ browser }}</td>
      </tr>
      <tr>
        <td class="tdtitle">视频监控</td>
        <td class="tdcontent">
          <span style="color: #5fa4f9;font-size: 18px" v-if="TRTCSupport">✓</span>
          <span style="color: #f35a5a;font-size: 18px" v-else>✕</span>
        </td>
      </tr>
      <tr>
        <td class="tdtitle">屏幕共享</td>
        <td class="tdcontent">
          <span style="color: #5fa4f9;font-size: 18px" v-if="screenMediaSupport">✓</span>
          <span style="color: #f35a5a;font-size: 18px" v-else>✕</span>
        </td>
      </tr>
      <tr>
        <td class="tdtitle">网络延时：</td>
        <td class="tdcontent">
          {{ rtt }}ms
        </td>
      </tr>
      <tr>
        <td class="tdtitle">上行网络：</td>
        <td class="tdcontent">
          <span :class="getColorOfStr(uplinkQuality)">
            {{ getNetworkQuality(uplinkQuality) }}
          </span>
        </td>
      </tr>
      <tr>
        <td class="tdtitle">下行网络：</td>
        <td class="tdcontent">
          <span :class="getColorOfStr(downlinkQuality)">
            {{ getNetworkQuality(downlinkQuality) }}
          </span>
        </td>
      </tr>
    </table>
    <div class="btn-container">
      <el-button
        style="width:100px"
        @click="
          () => {
            clearStream();
            step=1
            $emit('next');
          }
        "
        >重新检测</el-button
      >
      <el-button type="primary" style="width: 100px" @click="next" v-if="hasNext">
        下一步
      </el-button>
    </div>
  </div>
  </div>
 </template>

<script>
import TRTC from 'trtc-js-sdk'
import audioBar from './components/audioBar.vue'
import RTCDetect from 'rtc-detect'
import api from '../../api/api'
import GlobalDialog from '@/components/GlobalDialog'
export default {
  data() {
    return {
      step: 1,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      isAllPass: false,
      hasNetworkConnect: false,
      hasCameraDevice: false,
      hasMicrophoneDevice: false,
      hasSpeakerDevice: false,
      camera: '',
      cameraOptions: [],
      cameraHasInit: true,
      micro: '',
      microOptions: [],
      speaker: '',
      speakerOptions: [],
      url: 'https://exam-file.iguokao.com/resource/netspeed.mp3',
      volume: 0,
      system: '',
      browser: '',
      TRTCSupport: false,
      screenMediaSupport: false,
      localCameraStream: null,
      localMicroStream: null,
      timer: null,
      option: null,
      rtt: 0,
      downlinkQuality: 0,
      uplinkQuality: 0,
      uplinkClient: null,
      downlinkClient: null,
      allowCameraVisible: false
    }
  },
  props: {
    hasNext: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy() {
    this.uplinkClient.leave()
    this.downlinkClient.leave()
  },
  components: {
    audioBar,
    GlobalDialog
  },
  mounted() {
    this.getDevices()
  },
  methods: {
    next() {
      this.$router.push({ path: '/basic' })
    },
    async getDevices() {
      let cameraList
      let micList
      let speakerList
      try {
        cameraList = await TRTC.getCameras()
        micList = await TRTC.getMicrophones()
        speakerList = await TRTC.getSpeakers()
      } catch (error) {
        console.log('rtc-device-detector getDeviceList error', error)
      }
      this.hasCameraDevice = cameraList.length > 0
      this.hasMicrophoneDevice = micList.length > 0
      this.hasSpeakerDevice = speakerList.length > 0
      this.hasNetworkConnect = (await this.isOnline())
      if (
        this.hasCameraDevice &&
          this.hasMicrophoneDevice &&
          this.hasSpeakerDevice &&
          this.hasNetworkConnect
      ) {
        this.isAllPass = true
      }
      this.getDevicesList(cameraList, micList, speakerList)
    },
    // 获取设备列表
    async getDevicesList(c, m, s) {
      if (this.hasCameraDevice) {
        this.cameraOptions = c.map((camera) => {
          return {
            label: camera.label,
            value: camera.deviceId
          }
        })
      }
      if (this.hasMicrophoneDevice) {
        this.microOptions = m.map((micro) => {
          return {
            label: micro.label,
            value: micro.deviceId
          }
        })
      }
      if (this.hasSpeakerDevice) {
        this.speakerOptions = s.map((speaker) => {
          return {
            label: speaker.label,
            value: speaker.deviceId
          }
        })
      }
    },
    async isOnline() {
      const url = process.env.NODE_ENV === 'production'
        ? `https://exam-file.iguokao.com/resource/netspeed.mp3?${Date.now()}`
        : 'https://exam-file.igky.cc/speed_test/speaker_test_exam.mp3'
      return new Promise((resolve) => {
        try {
          const xhr = new XMLHttpRequest()
          xhr.onload = function() {
            resolve(true)
          }
          xhr.onerror = function() {
            resolve(false)
          }
          xhr.open('GET', url, true)
          xhr.send()
        } catch (err) {
          console.log(err)
        }
      })
    },
    // 初始化摄像头流
    async initCameraStream(cameraID) {
      try {
        this.localCameraStream = TRTC.createStream({
          video: true,
          audio: false,
          cameraId: cameraID
        })
        await this.localCameraStream.initialize()
        this.localCameraStream.play('camera-video')
        this.cameraHasInit = false
      } catch (error) {
        if (error.toString().includes('Permission denied')) {
          this.allowCameraVisible = true
        }
      }
    },
    // 更改摄像头
    async handleCameraChange(
      camera,
      cameraDevice
    ) {
      if (this.localCameraStream) {
        this.localCameraStream.switchDevice('video', camera)
      } else {
        this.initCameraStream(camera)
      }
    },
    async initMicroStream(microphoneID) {
      this.localMicroStream = TRTC.createStream({
        video: false,
        audio: true,
        microphoneId: microphoneID
      })
      await this.localMicroStream.initialize()
      this.localMicroStream.play('audio-container')
      this.timer = window.setInterval(() => {
        if (this.localMicroStream) {
          this.volume = this.localMicroStream.getAudioLevel()
        }
      }, 100)
    },
    // 更改麦克风
    async handleMicroChange(micro, microDevice) {
      if (this.localMicroStream) {
        this.localMicroStream.switchDevice('audio', micro)
      } else {
        this.initMicroStream(micro)
      }
    },
    async handleSpeakerChange(speaker) {
      const audio = document.querySelector('#audio-player')
      if (audio) {
        await audio.setSinkId(speaker)
      }
    },
    // 关闭时清空stream
    async clearStream() {
      this.localCameraStream && this.localCameraStream.stop()
      this.localCameraStream = null
      this.localMicroStream && this.localMicroStream.stop()
      this.localMicroStream = null
      this.uplinkClient.leave()
      this.downlinkClient.leave()
    },
    // 处理所有下一步
    async nextStep(hasError) {
      this.step++
      if (this.step === 2) {
        const optionValue = this.cameraOptions[0]
        if (optionValue) {
          await this.handleCameraChange(optionValue.value, optionValue)
          this.camera = optionValue.value
        }
      } else if (this.step === 3) {
        hasError && (this.step1 = true)
        const optionValue = this.microOptions[0]
        if (optionValue) {
          await this.handleMicroChange(optionValue.value, optionValue)
          this.micro = optionValue.value
        }
      } else if (this.step === 4) {
        hasError && (this.step2 = true)
        const optionValue = this.speakerOptions[0]
        if (optionValue) {
          await this.handleSpeakerChange(optionValue.value, optionValue)
          this.speaker = optionValue.value
        }
      } else if (this.step === 5) {
        // 如果上一步没有关闭audio声音，在这里关上
        this.closeAudio()
        hasError && (this.step3 = true)
        await this.getNetInfo()
        await this.getTestSig()
        await this.checkNetwork()
        setTimeout(() => {
          console.log('leave')
          this.uplinkClient.leave()
          this.downlinkClient.leave()
        }, 45000)
      }
    },
    // 关闭 audio
    closeAudio() {
      const audio = document.getElementById('audio-player')
      if (!audio.paused) {
        audio.pause()
      }
    },
    // 检测网络和系统信息
    async getNetInfo() {
      const detect = new RTCDetect()
      const systemResult = detect.getSystem()
      const webRTCSupportResult = await detect.isTRTCSupported()
      const APISupportResult = detect.getAPISupported()

      this.system = systemResult.OS
      this.browser = `${systemResult.browser.name} ${systemResult.browser.version}`
      this.TRTCSupport = webRTCSupportResult.result
      this.screenMediaSupport = APISupportResult.isScreenCaptureAPISupported
    },
    async getTestSig() {
      const res = await api.getTestSig()
      if (res.code === 0) {
        this.option = res.data
      }
    },
    // 获取上行网络质量和RTT
    async testUplinkNetworkQuality() {
      const { roomNumber, userId, sig, appId } = this.option[0]
      this.uplinkClient = TRTC.createClient({
        sdkAppId: appId,
        userId,
        userSig: sig,
        mode: 'rtc'
      })

      const uplinkStream = TRTC.createStream({ audio: false, video: true })
      await uplinkStream.initialize()

      this.uplinkClient.on('network-quality', async(event) => {
        const { uplinkNetworkQuality } = event
        this.uplinkQuality = uplinkNetworkQuality
        this.rtt = (await this.uplinkClient.getTransportStats()).rtt
      })

      // 加入用于测试的房间
      try {
        await this.uplinkClient.join({ roomId: roomNumber })
      } catch (error) {
        window.$message.error('检测网络失败，请更换网络或者刷新重试！')
        this.uplinkQuality = 6
      }
      await this.uplinkClient.publish(uplinkStream)
    },

    // 获取下行网络质量
    async checkNetwork() {
      const { roomNumber, userId, sig, appId } = this.option[1]
      this.downlinkClient = TRTC.createClient({
        sdkAppId: appId,
        userId,
        userSig: sig,
        mode: 'rtc'
      })
      this.downlinkClient.on('stream-added', async(event) => {
        await this.downlinkClient.subscribe(event.stream, { audio: false, video: true })
        this.downlinkClient.on('network-quality', (event) => {
          const { downlinkNetworkQuality } = event
          this.downlinkQuality = downlinkNetworkQuality
          if (downlinkNetworkQuality === 0) {
            return
          }
          if (downlinkNetworkQuality === 1 || downlinkNetworkQuality === 2 || downlinkNetworkQuality === 3) {
            this.checkLoading = false
          } else {
            this.checkLoading = false
          }
        })
      })
      try {
        await this.downlinkClient.join({ roomId: roomNumber }) // 加入用于测试的房间
      } catch (error) {
        this.downlinkQuality = 6
        window.$message.error('检测网络失败，请更换网络或者刷新重试！')
      }
      this.testUplinkNetworkQuality()
    },
    getColorOfStr(quality) {
      switch (quality) {
        case 0:
        case 1:
        case 2:
        case 3:
          return 'normal'
        case 4:
        case 5:
        case 6:
          return 'abnormal'
        default:
          return 'abnormal'
      }
    },
    // 获取网络质量
    getNetworkQuality(quality) {
      switch (quality) {
        case 0:
          return '检测中...'
        case 1:
          return '极佳'
        case 2:
          return '较好'
        case 3:
          return '一般'
        case 4:
          return '差'
        case 5:
          return '极差'
        case 6:
          return '断开'
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__wrapper {
  z-index: 3000 !important;
}

.detect-result {
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 60px;
    color: #cb2a1d;
  }
  .right {
    margin-left: 20px;
    .title {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .content {
      display: block;
    }
    .content2 {
      display: block;
      margin-top: 10px;
      color: #cb2a1d;
    }
  }
}
.title {
  font-size: 32px;
}
.subtitle {
  font-size: 14px;
  color: #666;
  margin: 16px 0 24px 0;
}
.label {
  width: 100px;
  font-size: 14px;
  color: #666;
}
.step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
  .net-item {
    align-self: flex-start;
    font-size: 14px;
    color: #666;
    margin: 16px 0;
    width: 100%;
    .tdtitle {
      text-align: right;
      padding-right: 20px;
      width: 150px;
      font-weight: bold;
    }
    .tdcontent {
      text-align: left;
      font-weight: bold;
      padding-left: 20px;
      .normal {
        color: #5fa4f9;
      }
      .abnormal {
        color: #f35a5a;
      }
    }
  }
  .say-hello {
    margin: 8px 0;
    align-self: flex-start;
    font-size: 14px;
  }
  .hint {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin: 16px 0;
  }
  .btel-container {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .select-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px 0 8px 0;
  }
  #camera-video {
    width: 300px;
    height: 225px;
    border-radius: 10px;
    overflow: hidden;
  }
  #audio-player {
    margin: 20px 0 10px 0;
  }
  audio::-webkit-media-controls-panel {
    background-color: rgba(243, 90, 90, 0.1);
  }
  .devices {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .items {
      margin: 0 10px 24px 0;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 100%;
        box-shadow: 6px 6px 8px 0 rgba(0,0,0,0.1);
        position: relative;
        font-size: 32px;
        .pos {
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 14px;
        }
        .iconduigoutianchong-01 {
          color: #5fa4f9;
        }
        .iconicon_tips_wrong {
          color: #f35a5a;
        }
      }
      .pass {
        color: #5fa4f9;
      }
      .no-pass {
        color: #f35a5a;
      }
    }
  }
  .btn {
    margin-top: 24px;
  }
  .tips {
    color: #5fa4f9;
    font-size: 14px;
  }
}
</style>
